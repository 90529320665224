// Customizable Area Start
import React from "react";
import HostSetupRssFeedController, {
    Props,
} from "../HostSetupRssFeedController";
import { validatedPreview, goBack, preview1440 } from "../assets";
import NavigationMenu from "../../../../blocks/navigationmenu/src/NavigationMenu.web";
import { handleNavigation } from "../../../utilities/src/CustomHelpers";
import Footer from "../../../../blocks/navigationmenu/src/Footer.web";

const contactUsLink = "https://www.podcasttown.net/contact-us"
const WIDE_SCREENS = typeof window !== 'undefined' && window.innerWidth > 1750

export default class SetupRssFeedPreview extends HostSetupRssFeedController {
    constructor(props: Props) {
        super(props);
    }
    
    
    render() {
        const { isLinkValid } = this.state

        return (
            <>
             <NavigationMenu navigation={this.props.navigation} id='1' />
            <div style={styles.mainWrapper}>
                <div style={styles.title}>
                    <img onClick={this.handleGoBack} src={goBack} width={24} height={24}/>
                    <span>Import Existing RSS Feed - Preview</span>
                </div>
                {WIDE_SCREENS ? 
                <div style={styles.container}>
                    <div style={styles.leftBlock}>
                        {!isLinkValid ? 
                            <div style={styles.content}>
                                <div style={{fontSize: "16px", fontWeight: 400, textAlign: "center"}}>
                                    Unable to display RSS Feed Preview due to validation failure. 
                                </div>
                                <br/>
                                <div style={{fontSize: "18px", fontWeight: 900, textAlign: "center"}}>
                                    Please Go Back And Confirm Your RSS Feed Link With Your Current Podcast Host.
                                </div>
                            </div>
                            : 
                            <img src={validatedPreview} />
                        }
                    </div>
                <div style={styles.rightBlock}>
                    <div style={styles.buttonsWrapper}>
                        {!isLinkValid ?
                            <button
                                disabled
                                style={styles.button} 
                                data-test-id="continueBtn">
                                Go To Template and Color Scheme Choices
                            </button> 
                            : 
                            <button
                                onClick={() => handleNavigation("ChooseTemplate", this.props)}
                                style={{...styles.button, backgroundColor: "#FF0807"}} 
                                data-test-id="continueBtn">
                                Go To Template and Color Scheme Choices
                            </button>
                        }
                        <button
                            onClick={() => this.openLink(contactUsLink)}
                            style={{...styles.button, backgroundColor: "#030303"}} 
                            data-test-id="contactPlatform">
                            Contact the Platform
                        </button>
                    </div>
                </div>
            </div>
            :   
            <div>
                <div style={styles.smContainer}>
                    {isLinkValid ? 
                        <img src={preview1440} />
                    :
                    <div style={styles.content}>
                        <div style={{fontSize: "16px", fontWeight: 400, textAlign: "center"}}>
                            Unable to display RSS Feed Preview due to validation failure. 
                        </div>
                        <br/>
                        <div style={{fontSize: "18px", fontWeight: 900, textAlign: "center"}}>
                            Please Go Back And Confirm Your RSS Feed Link With Your Current Podcast Host.
                        </div>
                    </div>
                }
                 </div>
                    <div 
                        style={{
                            display: "flex", 
                            gap: 30,
                            margin: "10px 0",
                            justifyContent: "flex-end",
                            paddingRight: "55px"}}>
                        {!isLinkValid ? 
                            <button
                                disabled
                                style={styles.button} 
                                data-test-id="continueBtn">
                                Go To Template and Color Scheme Choices
                            </button> 
                        : 
                            <button
                                onClick={() => handleNavigation("ChooseTemplate", this.props)} 
                                style={{...styles.button, backgroundColor: "#FF0807"}} 
                                data-test-id="continueBtn">
                                Go To Template and Color Scheme Choices
                            </button>
                        }
                    <button
                        onClick={() => this.openLink(contactUsLink)}
                        style={{...styles.button, backgroundColor: "#030303", border: "1px solid white"}} 
                        data-test-id="contactPlatform">
                        Contact the Platform
                    </button>
                </div>
            </div>
            }

           </div>
           <Footer navigation={this.props.navigation} id={"1"} />
            </>
            
            
        );
    }

}
const styles = {
    mainWrapper: {
        paddingRight: "5%",
        paddingLeft: "calc(6% + 135px)",
        maxHeight: "75vh",
        overflowY: "scroll" as const
    },
    container: {
        display: "flex",
        gap: 30,
        color : "white",
        width: "100%",
    },
    title: {
        display: "flex",
        alignItems: "center",
        gap: 10,
        color: 'white', 
        paddingBottom: "1%",
        fontWeight: 900, 
        fontSize: "30px"
    },
    leftBlock: {
        width: "65%",
        height: "80vh",
        background:"#312F2D",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rightBlock: {
        width: "35%",
        height: "80vh",
        background:"#312F2D",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    content: {
        width: "50%",
        background:"#44403C",
        margin: "0 auto",
        color: "white",
        padding: "25px 40px",
        justifyContent: "center",
        alignItems: "center",    
    },
    button: {
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
        height: "4%",
        width: "380px",
        background: "#B2B3B3",
        color: "white",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 900,
        textTransform: "capitalize" as const,
        marginTop: "3%",
        border: "none",
        textAlign: "center" as const,
        padding: "16px",
    },
    buttonsWrapper: {
        display: "flex",
        flexDirection: "column" as const,
        gap: 10
    },
    smContainer: {
        color : "white",
        width: "95%",
        minHeight: "80vh",
        background:"#312F2D",
        paddingBottom: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
    
}
// Customizable Area End
