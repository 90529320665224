// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  isLinkValid: boolean,
  selectedTemplate: string
}

export interface SS {
  id: any;
}

interface Podcast {
    id: number, 
    sender: string, 
    subject: string, 
    snippet: string, 
    time: string, 
    isRead: boolean 
  }

export default class ChooseTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchRssFeedCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: true,
      selectedTemplate: '',
    };
  }

  async componentDidMount() {
     // Customizable Area End
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  getAllStorageData = () => {
    const data: Record<string, any> = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== null) { 
        const value = localStorage.getItem(key);
        if (value !== null) {
            data[key] = value; 
        }
    }
    }
    return data;
};
  handleTemplateClick = (template: string) => {
    this.setState({ selectedTemplate: template });
  };
  handleContinue = () => {
    let storageData = this.getAllStorageData();
    if (!storageData['rssData']) {
      storageData['rssData'] = {}; 
    } else {
      storageData['rssData'] = JSON.parse(storageData['rssData']);
  }
    storageData['rssData']['selectedTemplate'] = this.state.selectedTemplate;
    setStorageData("rssData", JSON.stringify(storageData['rssData']));
    handleNavigation("TemplateColorScheme", this.props)
  }

}
// Customizable Area End
