import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button, IconButton, Card, CardContent, CardMedia, Link, Grid, Dialog, DialogContent, DialogActions, Divider } from "@mui/material";
import { Star, StarBorder, StarHalf, Phone, InfoOutlined, AttachMoney, Laptop, ShoppingCartOutlined, LocalOfferOutlined, MenuBook, ThumbUpOffAlt, ChatBubbleOutline, Share, FavoriteBorder, PlaylistAdd, PlayCircleFilled, PhotoLibrary, FileUploadOutlined, CloseOutlined } from '@mui/icons-material';
import { podcastImage, tshirtIcon, recommended_episode_1, recommended_episode_2, recommended_episode_3, ad_1, ad_2, appLogo, goBack } from "./assets";
import {
  createTheme,
} from "@mui/material/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
// Customizable Area End
import TemplatePreviewWithLogoController, { Props } from "./TemplatePreviewWithLogoController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class TemplatePreviewWithLogo extends TemplatePreviewWithLogoController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} id='1' />
        <StyledWrapperSetting>
          <Box display="flex" alignItems="center" mb={3}>
            <img onClick={this.handleGoBack} src={goBack} alt="Go Back" style={{ marginBottom: '20px', marginLeft: "91px", width: "2%" }} />
            <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "10px", fontWeight: "bold" }}>
              Preview Color Scheme And Template
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <div className="preview-box">
              <div className="fullscreen-ui">
                {this.state.imagePreviewUrl && (
                  <img
                    src={this.state.imagePreviewUrl}
                    alt="Preview"
                    style={{ width: '20%', height: '8rem', borderRadius: '10px' }}
                  />
                )}
                <Box className="header-section">
                  <Box display="flex" flexDirection="row" justifyContent="space-between" gap="20px">
                    <Box display="flex" flexDirection="row" gap="20px">
                      <img src={podcastImage} alt="Podcast" className="podacst-image" />
                      <Box className="podcast-header-content">
                        <Typography variant="h5" component="h2" style={{ fontWeight: "900", lineHeight: "40px", fontSize: "30px" }}>The True Podcast</Typography>
                        <Typography className="podcast-name">COST OF WINNING</Typography>
                        <Box display="flex" alignItems="center">
                          {[...Array(4)].map((_, index) => (
                            <IconButton key={`full-${index}`} style={{ padding: 0 }}>
                              <Star style={{ color: '#fff' }} />
                            </IconButton>
                          ))}
                          <IconButton key="half-star" style={{ padding: 0 }}>
                            <StarHalf style={{ color: '#fff' }} />
                          </IconButton>
                          {[...Array(0)].map((_, index) => (
                            <IconButton key={`empty-${index}`} style={{ padding: 0 }}>
                              <StarBorder style={{ color: '#fff' }} />
                            </IconButton>
                          ))}
                        </Box>
                        <Box display="flex" gap="8px" marginBottom="10px">
                          <Button variant="outlined" className="left-section-buttons">Rate or Review</Button>
                          <Button variant="outlined" className="left-section-buttons">Share Channel</Button>
                        </Box>
                        <Typography className="total-reviews">1.5K Reviews</Typography>
                        <Box display="flex" gap="10px" marginTop="10px">
                          <Button variant="contained" className="left-section-bottom-buttons" data-test-id="follow-button" style={{ backgroundColor: "#FF5722" }} >Follow</Button>
                          <Button variant="contained" className="left-section-bottom-buttons"><AttachMoney style={{ width: "24px", height: "24px" }} />Subscribe</Button>
                          <IconButton className="podcast-header-info-icon"><InfoOutlined /></IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-start" style={{ marginTop: '40px' }}>
                      <Box display="flex" flexDirection="column" gap="20px">
                        <Box display="flex" flexDirection="row" gap="20px">
                          <Box display="flex" flexDirection="column" gap="10px">
                            <Button variant="outlined" style={{ backgroundColor: '#FF0807', borderColor: '#FF0807', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold" }}><Laptop style={{ width: "24px", height: "20px" }} />Webinar</Button>
                            <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold" }}><img src={tshirtIcon} />Buy Swag</Button>
                            <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold" }}><AttachMoney style={{ width: "24px", height: "24px" }} />Support Host</Button>
                          </Box>
                          <Box display="flex" flexDirection="column" gap="10px">
                            <Button variant="outlined" style={{ backgroundColor: '#FF0807', borderColor: '#FF0807', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold" }}><ShoppingCartOutlined style={{ width: "24px", height: "20px" }} />Buy Now</Button>
                            <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold" }}><MenuBook style={{ width: "24px", height: "20px" }} />Learn More</Button>
                            <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: 'capitalize', fontWeight: "bold" }}><LocalOfferOutlined style={{ width: "24px", height: "20px" }} />Get Offer</Button>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="10px">
                          <Button variant="outlined" style={{ color: '#fff', width: '345px', height: "44px", borderRadius: "8px", border: "1px solid white", fontWeight: "bold", textTransform: "capitalize" }} startIcon={<Phone />}>
                            Contact Host
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="left-body-section-tabs">
                  <Box sx={{ display: 'flex', gap: '22px' }}>
                    <Button variant="outlined" className="section-tabs" data-test-id="tab-button" sx={{ borderRadius: '9px 9px 0px 0px', height: '53px' }}>Episodes</Button>
                    <Button variant="outlined" className="section-tabs" data-test-id="tab-button" sx={{ borderRadius: '9px', height: '40px' }}>Community</Button>
                    <Button variant="outlined" className="section-tabs" data-test-id="tab-button" sx={{ borderRadius: '9px', height: '40px' }}>Channel Info</Button>
                  </Box>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '24px' }}>
                  <div className="scrollableWrapper">
                    <Box className="left-body-section" sx={{ overflowY: 'scroll', maxHeight: '64rem' }}>
                      <Card className="podcast-card">
                        <CardMedia
                          component="img"
                          sx={{ width: 151 }}
                          image={podcastImage}
                          alt="Podcast episode"
                          className="podcast-media"
                        />
                        <Box className="podcast-inner-box">
                          <CardContent className="podcast-inner-box-content">
                            <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                              The Cost Of Success
                            </Typography>
                            <Typography variant="subtitle1" color="#fff" component="div" id="ep-10" className="podcast-inner">
                              The True
                            </Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                              Episode 10
                            </Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                              Sed ut perspiciatis unde omnis iste natus error sit ...
                              <Link style={{ color: '#fff' }} id="ep-10-link">See More</Link>
                            </Typography>
                          </CardContent>
                          <Box className="podcast-action-buttons">
                            <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-10-like">
                              <ThumbUpOffAlt />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.4K</Typography>
                            <IconButton aria-label="comment" sx={{ color: 'white' }}>
                              <ChatBubbleOutline />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">985</Typography>
                            <IconButton aria-label="share" sx={{ color: 'white' }}>
                              <Share />
                            </IconButton>
                            <Typography sx={{ color: 'white' }} className="podcast-inner">1.2K</Typography>
                          </Box>
                        </Box>
                        <Box className="podcast-saving-options" id="ep-10-buttons">
                          <Button variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder />}>
                            <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                          </Button>
                          <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd />}>
                            <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                          </Button>
                        </Box>
                        <Box className="play-icon" id="ep-10-play-button">
                          <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
                        </Box>
                      </Card>
                      <Card className="podcast-card">
                        <CardMedia
                          component="img"
                          sx={{ width: 151 }}
                          image={podcastImage}
                          alt="Podcast episode"
                          className="podcast-media"
                        />
                        <Box className="podcast-inner-box">
                          <CardContent className="podcast-inner-box-content">
                            <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                              The Cost Of Process
                            </Typography>
                            <Typography variant="subtitle1" color="#fff" component="div" id="ep-9" className="podcast-inner">
                              The True
                            </Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                              Episode 9
                            </Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                              Sed ut perspiciatis unde omnis iste sit voluptatem accusantium...
                              <Link style={{ color: '#fff' }} id="ep-9-link">See More</Link>
                            </Typography>
                          </CardContent>
                          <Box className="podcast-action-buttons">
                            <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-9-like">
                              <ThumbUpOffAlt />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.3K</Typography>
                            <IconButton aria-label="comment" sx={{ color: 'white' }}>
                              <ChatBubbleOutline />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">984</Typography>
                            <IconButton aria-label="share" sx={{ color: 'white' }}>
                              <Share />
                            </IconButton>
                            <Typography sx={{ color: 'white' }} className="podcast-inner">1.3K</Typography>
                          </Box>
                        </Box>
                        <Box className="podcast-saving-options" id="ep-9-button">
                          <Button variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder />}>
                            <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                          </Button>
                          <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd />}>
                            <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                          </Button>
                        </Box>
                        <Box className="play-icon" id="ep-9-play-button">
                          <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
                        </Box>
                      </Card>
                      <Card className="podcast-card">
                        <CardMedia
                          component="img"
                          sx={{ width: 151 }}
                          image={podcastImage}
                          alt="Podcast episode"
                          className="podcast-media"
                        />
                        <Box className="podcast-inner-box">
                          <CardContent className="podcast-inner-box-content">
                            <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                              The Cost Of Failure
                            </Typography>
                            <Typography variant="subtitle1" color="#fff" component="div" id="ep-8" className="podcast-inner">
                              The True
                            </Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                              Episode 8
                            </Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                              Sed ut perspiciatis iste natus error sit voluptatem accusantium...
                              <Link style={{ color: '#fff' }} id="ep-8-link">See More</Link>
                            </Typography>
                          </CardContent>
                          <Box className="podcast-action-buttons">
                            <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-8-like">
                              <ThumbUpOffAlt />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.5K</Typography>
                            <IconButton aria-label="comment" sx={{ color: 'white' }}>
                              <ChatBubbleOutline />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">988</Typography>
                            <IconButton aria-label="share" sx={{ color: 'white' }}>
                              <Share />
                            </IconButton>
                            <Typography sx={{ color: 'white' }} className="podcast-inner">1.9K</Typography>
                          </Box>
                        </Box>
                        <Box className="podcast-saving-options" id="ep-8-button">
                          <Button variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder />}>
                            <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                          </Button>
                          <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd />}>
                            <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                          </Button>
                        </Box>
                        <Box className="play-icon" id="ep-8-play-button">
                          <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
                        </Box>
                      </Card>
                      <Card className="podcast-card">
                        <CardMedia
                          component="img"
                          sx={{ width: 151 }}
                          image={podcastImage}
                          alt="Podcast episode"
                          className="podcast-media"
                        />
                        <Box className="podcast-inner-box">
                          <CardContent className="podcast-inner-box-content">
                            <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                              The Cost Of You
                            </Typography>
                            <Typography variant="subtitle1" color="#fff" component="div" id="ep-7" className="podcast-inner">
                              The True
                            </Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                              Episode 7
                            </Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                              Sed ut iste natus error sit voluptatem accusantium...
                              <Link style={{ color: '#fff' }} id="ep-7-link">See More</Link>
                            </Typography>
                          </CardContent>
                          <Box className="podcast-action-buttons">
                            <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-7-like">
                              <ThumbUpOffAlt />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">2.3K</Typography>
                            <IconButton aria-label="comment" sx={{ color: 'white' }}>
                              <ChatBubbleOutline />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">884</Typography>
                            <IconButton aria-label="share" sx={{ color: 'white' }}>
                              <Share />
                            </IconButton>
                            <Typography sx={{ color: 'white' }} className="podcast-inner">2.2K</Typography>
                          </Box>
                        </Box>
                        <Box className="podcast-saving-options" id="ep-7-buttons">
                          <Button variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder />}>
                            <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                          </Button>
                          <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd />}>
                            <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                          </Button>
                        </Box>
                        <Box className="play-icon" id="ep-7-play-button">
                          <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
                        </Box>
                      </Card>
                    </Box>
                  </div>
                  <Box sx={{ minHeight: '100vh' }}>
                    <Grid container direction="column" spacing={2} className="right-body-section">
                      <Typography className="sponsored-title" id='sponsored'>Sponsored Recommendations</Typography>
                      <Grid item>
                        <Card className="recommended-cards">
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                              <Typography variant="h6" sx={{ color: '#fff', fontFamily: "Nexa-Heavy" }}>
                                The True
                              </Typography>
                              <Typography variant="body2" sx={{ color: '#fff', fontFamily: "Nexa-Regular" }}>
                                Cost of Success Ep. 9
                              </Typography>
                            </CardContent>
                          </Box>
                          <CardMedia
                            component="img"
                            image={recommended_episode_1}
                            alt="The True Episode"
                            sx={{
                              width: "20%",
                              height: "100%",
                              marginLeft: 'auto',
                              objectFit: 'cover'
                            }}
                          />
                        </Card>
                      </Grid>
                      <Grid item>
                        <Card className="recommended-cards">
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                              <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }}>
                                All Consuming
                              </Typography>
                              <Typography variant="body2" sx={{ color: '#fff', fontFamily: 'Nexa-Regular' }}>
                                Fantomy Ep. 12
                              </Typography>
                            </CardContent>
                          </Box>
                          <CardMedia
                            component="img"
                            image={recommended_episode_2}
                            alt="The True Episode"
                            sx={{
                              width: "20%",
                              height: "100%",
                              marginLeft: 'auto',
                              objectFit: 'cover'
                            }}
                          />
                        </Card>
                      </Grid>
                      <Grid item>
                        <Card className="recommended-cards">
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                              <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }}>
                                This American Life
                              </Typography>
                              <Typography variant="body2" sx={{ color: '#fff', fontFamily: 'Nexa-Regular' }}>
                                Love your era Ep. 8
                              </Typography>
                            </CardContent>
                          </Box>
                          <CardMedia
                            component="img"
                            image={recommended_episode_3}
                            alt="The True Episode"
                            sx={{
                              width: "20%",
                              height: "100%",
                              marginLeft: 'auto',
                              objectFit: 'cover'
                            }}
                          />
                        </Card>
                      </Grid>
                    </Grid>
                    <Box display="flex" flexDirection="column" gap="25px">
                      <Typography variant="h6" sx={{ color: '#fff', marginTop: 4, fontFamily: 'Nexa-Heavy' }}>
                        Advertising
                      </Typography>
                      <Box
                        component="img"
                        src={ad_1}
                        alt="Burger Ad"
                        sx={{ height: '20rem', borderRadius: "13px" }}
                      />
                      <Box
                        component="img"
                        src={ad_2}
                        alt="Heinz Ad"
                        sx={{ height: '20rem', borderRadius: "13px" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
            <Box
              sx={{
                width: '35%',
                height: '70rem',
                backgroundColor: '#312F2D',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                padding: '16px',
                marginLeft: '2%'
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: '50%',
                  backgroundColor: '#FF5722',
                  color: '#FFFFFF',
                  marginBottom: '5%',
                  textTransform: 'capitalize',
                  fontFamily: 'Nexa-Heavy',
                  '&:hover': {
                    backgroundColor: '#FF5722',
                    borderColor: "#FF5722"
                  },
                }}
                data-test-id='finish-set-up'
              >
                Finish Set Up
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: '50%',
                  color: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  marginBottom: '5%',
                  textTransform: 'capitalize',
                  fontFamily: 'Nexa-Heavy',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: "#FFFFFF"
                  },
                }}
                onClick={() => handleNavigation('ChooseTemplate', this.props)}
                data-test-id='new-template'
              >
                Select A New Template
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: '50%',
                  color: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  textTransform: 'capitalize',
                  fontFamily: 'Nexa-Heavy',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: "#FFFFFF"
                  },
                }}
                onClick={() => handleNavigation('TemplateColorScheme', this.props)}
                data-test-id='new-color-scheme'
              >
                Select A New Color Scheme
              </Button>
            </Box>
          </Box>
        </StyledWrapperSetting>
        <Footer navigation={this.props.navigation} id={"1"} />
        <Dialog open={this.state.openFirstDialog} onClose={this.handleCloseFirstDialog} PaperProps={{
          style: {
            width: '27%',
            margin: '0 auto',
            borderBottomRightRadius: '30px',
            height: '33%'
          },
        }}>
            <IconButton 
              onClick={this.handleCloseFirstDialog} 
              style={{ 
                position: 'absolute', 
                right: 8, 
                top: 8, 
                color: '#fff' 
              }}
              data-test-id= "first-dialog-close-icon"
            >
              <CloseOutlined />
            </IconButton>
          <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C" }}>
            <IconButton size="large" className="modal-icon">
              <PhotoLibrary fontSize="inherit" style={{ color: "#fff" }} />
            </IconButton>
            <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Heavy' }} gutterBottom>
              Would you like to upload your logo to replace the PodcastTown logo on the top of the screen?
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'center', backgroundColor: "#44403C", height: '18%' }}>
            <Button onClick={this.handleCloseFirstDialog} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy' }} data-test-id="not-now">
              Not Now
            </Button>
            <Button onClick={this.handleOpenSecondDialog} variant="contained" style={{ backgroundColor: "#FF0807", textTransform: "capitalize", fontFamily: 'Nexa-Heavy' }} data-test-id="sure">
              Sure!
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.openSecondDialog} onClose={this.handleCloseSecondDialog} PaperProps={{
          style: {
            width: '35%',
            margin: '0 auto',
            borderBottomRightRadius: '30px',
            height: '43%'
          },
        }}>
          <IconButton 
              onClick={this.handleCloseSecondDialog} 
              style={{ 
                position: 'absolute', 
                right: 8, 
                top: 8, 
                color: '#fff' 
              }}
              data-test-id= "second-dialog-close-icon"
            >
              <CloseOutlined />
            </IconButton>
          <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C", padding: "30px" }}>
            <Typography variant="h6" align="center" style={{ color: "#fff", marginBottom: "2%", marginLeft: "45%", fontFamily: 'Nexa-Heavy' }}>
              New Logo Image:
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div style={{ width: '30%' }}>
                <img
                  src={appLogo}
                  alt="Logo"
                  style={{ width: '115%', height: '10rem', borderRadius: '10px' }}
                />
              </div>
              <div
                style={{
                  padding: '20px',
                  border: '2px dashed #fff',
                  borderRadius: '10px',
                  width: '51%',
                  height: '8rem',
                  backgroundColor: '#3D3D3D',
                }}
              >
                {this.state.imagePreviewUrl ? (
                  <img
                    src={this.state.imagePreviewUrl}
                    alt="Preview"
                    style={{ width: '95%', height: '8rem', borderRadius: '10px' }}
                  />
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <Box>
                      <FileUploadOutlined style={{ color: '#FF0807' }} />
                    </Box>
                    <Typography variant="body2" style={{ color: "#fff", fontFamily: 'Nexa-Heavy' }}>
                      Drag & Drop Your File Here OR
                    </Typography>
                    <Button
                      variant="contained"
                      component="label"
                      style={{ marginTop: '10px', backgroundColor: '#FF4500', fontFamily: 'Nexa-Heavy' }}
                    >
                      Browse File
                      <input
                        hidden
                        accept="image/*"
                        data-test-id="upload_file"
                        type="file"
                        onChange={this.handleFileChange}
                      />
                    </Button>
                    <Typography variant="body2" style={{ color: "#fff", marginTop: '10px', fontFamily: 'Nexa-Heavy' }}>
                      Max File Size: 200mb. Supports JPG and PNG files.
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'center', backgroundColor: "#44403C", height: '18%' }}>
            <Button onClick={this.handleCloseSecondDialog} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy' }} data-test-id="cancel">
              Cancel
            </Button>
            <Button
              onClick={this.handleCloseSecondDialog}
              variant="contained"
              disabled={!this.state.selectedFile}
              style={{
                backgroundColor: this.state.selectedFile ? "#FF4500" : "#A9A9A9",
                textTransform: 'capitalize',
                fontFamily: 'Nexa-Heavy'
              }}
              data-test-id="done"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  paddingLeft: "calc(2% + 115px)",
  paddingRight: "5%",
  height: "auto",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .preview-box": {
    width: "65%",
    padding: "41px 0px 22px 76px",
    position: "relative",
    overflow: "hidden",
    boxSizing: "borderBox",
    backgroundColor: "#312F2D",
    height: "70rem",
    marginLeft: "5%",
    borderRadius: '8px'
  },
  "& .fullscreen-ui": {
    width: "130%",
    height: "auto",
    transform: "scale(0.70)",
    transformOrigin: "top left"
  },
  "& .header-section": {
    backgroundColor: "#44403C",
    width: "102%",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    padding: "1rem",
    // marginLeft: "90px",
    marginTop: "18px"
  },
  "& .podcast-name": {
    fontWeight: "900",
    fontSize: "40px",
    lineHeight: "56px",
    textTransform: "uppercase",
    fontFamily: "Nexa-Heavy"
  },
  "& .podacst-image": {
    width: "269px",
    height: "259px",
    borderRadius: "5px",
    marginTop: "13px",
    marginLeft: "8px"
  },
  "& .podcast-header-content": {
    color: "white",
    fontWeight: "bold",
    marginTop: "13px"
  },
  "& .left-section-buttons": {
    color: "white",
    border: "1px solid white",
    borderRadius: "8px",
    marginTop: "5px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      color: "white",
      border: "1px solid white",
    }
  },
  "& .podcast-header-info-icon": {
    width: "44px",
    height: '44px',
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#FF0807",
    marginTop: "5px",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .total-reviews": {
    fontWeight: "900",
    fontSize: "18px",
    lineHeight: "22px",
    marginTop: "15px",
    fontFamily: "Nexa-Heavy"
  },
  "& .left-section-bottom-buttons": {
    backgroundColor: "#FF5722",
    color: "white",
    borderRadius: "8px",
    width: "164px",
    height: '44px',
    fontWeight: "bold",
    textTransform: 'capitalize',
    borderColor: "#FF5722",
    marginTop: "5px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#FF5722",
      borderColor: "#FF5722",
    }
  },
  "& .left-body-section-tabs": {
    // width: "536px",
    height: "52px",
    // marginLeft: "90px",
    marginTop: "22px"
  },
  "& .section-tabs": {
    color: 'white',
    backgroundColor: '#44403C',
    textTransform: "capitalize",
    borderColor: "#44403C",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: '#44403C',
      borderColor: "#44403C",
    },
    marginBottom: "0px"
  },
  "& .scrollableWrapper": {
    height: "65rem",
    position: "relative",
    paddingRight: "20px",
    overflow: "hidden",
  },
  "& .left-body-section": {
    height: "100%",
    width: "100%",
    overflowY: "scroll",
    paddingRight: "10px",
    scrollbarWidth: "thin",
    scrollbarColor: "#FF4500 #4D4D4D",

    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#4D4D4D",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF4500",
      borderRadius: "10px",
    },
  },
  "& .podcast-card": {
    display: 'flex',
    backgroundColor: '#44403C',
    gap: "24px",
    // width: "1005px",
    height: "242px",
    "&:first-of-type": {
      marginTop: "0px",
    },
    "&:not(:first-of-type)": {
      marginTop: "20px",
    }
  },
  "& .podcast-inner-box": {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  "& .podcast-inner-box-content": {
    flex: '1 0 auto',
    paddingBottom: 0
  },
  "& .podcast-action-buttons": {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingBottom: 10
  },
  "& .podcast-saving-options": {
    display: 'flex',
    flexDirection: 'column',
    gap: "9px"
  },
  "& .podcast-save-button": {
    color: 'white',
    borderColor: '#FF0807',
    marginRight: "0px",
    width: "118px",
    height: "26px",
    backgroundColor: "#FF0807",
    borderRadius: "8px",
    textTransform: "capitalize",
    gap: "8px",
    "&:hover": {
      borderColor: '#FF0807',
      backgroundColor: "#FF0807",
    },
    "&:first-of-type": {
      marginTop: "25px",
    },
    "&:not(:first-of-type)": {
      marginTop: "0px",
    }
  },
  "& .podcast-media": {
    width: "123px",
    height: "118px",
    marginTop: "34px",
    marginLeft: "44px",
    borderRadius: "3px"
  },
  "& .play-icon": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '10%',
    marginRight: "2%",
    marginTop: "19%"
  },
  "& .sponsored-title": {
    color: "#fff",
    marginLeft: "15px",
    fontFamily: 'Nexa-Heavy'
  },
  "& .right-body-section": {
    marginTop: "-35px",
    // width: "47.8%", 
    // height: "33%"
  },
  "& .recommended-cards": {
    display: 'flex',
    backgroundColor: '#44403C',
    color: '#fff',
    // width: '478px', 
    height: '80px',
    borderRadius: '6px',
    borderLeft: '5px solid red'
  },
  "& .podcast-inner": {
    fontFamily: 'Nexa-Heavy'
  }
});

// Customizable Area End
