import React from "react";
// Customizable Area Start
import {
    Button,
    Typography,
    styled,
    Box,
    Paper,
    TextField
} from "@mui/material";
import {
    createTheme,
} from "@mui/material/styles";
import { goBack } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { SketchPicker, ColorResult } from 'react-color';
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
// Customizable Area End
import TemplateColorSchemeController, { Props } from "./TemplateColorSchemeController";

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class TemplateColorScheme extends TemplateColorSchemeController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const stepLabelMap: Record<number, string> = {
            1: 'Background',
            2: 'Secondary',
            3: 'Text',
            4: 'Button'
        };

        const currentStepLabel = stepLabelMap[this.state.step] || 'Unknown';
        return (
            // Customizable Area Start
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <StyledWrapperSetting>
                    <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "96px", fontWeight: "bold", fontFamily: 'Nexa-Heavy' }}>
                        Choose Color Scheme
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '30px',
                            backgroundColor: '#000',
                            height: 'auto',
                            width: "100%",
                            gap: "40px"
                        }}
                    >
                        <Box display="flex" flexDirection="column" style={{ marginLeft: "22%" }}>
                            <Box display="flex" alignItems="center" mb={3}>
                                <img onClick={this.handleGoBack} src={goBack} width={24} height={24} alt="Go Back" data-test-id="go-back" />
                                <Typography variant="subtitle1" sx={{ color: '#fff', fontWeight: 'bold', marginLeft: '16px', fontFamily: 'Nexa-Heavy' }}>
                                    Choose a {ResolveCondition(this.state.step === 1, currentStepLabel, currentStepLabel)} Color
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mt={-1} mb={2} ml={11}>
                                <Box
                                    sx={{
                                        width: '12%',
                                        height: '82%',
                                        backgroundColor: ResolveCondition(
                                            this.state.step === 1,
                                            this.state.backgroundColor,
                                            ResolveCondition(
                                                this.state.step === 2,
                                                this.state.secondaryColor,
                                                ResolveCondition(
                                                    this.state.step === 3,
                                                    this.state.textColor,
                                                    this.state.buttonColor
                                                )
                                            )
                                        ),
                                        border: '1px solid #fff',
                                        margin: "5% 5% 5% -18%"
                                    }}
                                />
                                <TextField
                                    value={ResolveCondition(
                                        this.state.step === 1,
                                        this.state.backgroundColor,
                                        ResolveCondition(
                                            this.state.step === 2,
                                            this.state.secondaryColor,
                                            ResolveCondition(
                                                this.state.step === 3,
                                                this.state.textColor,
                                                this.state.buttonColor
                                            )
                                        )
                                    )}
                                    placeholder="#Hex code here"
                                    onChange={this.handleColorManually}
                                    variant="outlined"
                                    data-test-id="color-input-field"
                                    sx={{
                                        input: {
                                            color: 'white',
                                            backgroundColor: '#333',
                                            padding: '8px',
                                            fontFamily: 'Nexa-Heavy'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#777',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#fff',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            <Box ml={12}>
                                <SketchPicker
                                    color={ResolveCondition(
                                        this.state.step === 1,
                                        this.state.backgroundColor,
                                        ResolveCondition(
                                            this.state.step === 2,
                                            this.state.secondaryColor,
                                            ResolveCondition(
                                                this.state.step === 3,
                                                this.state.textColor,
                                                this.state.buttonColor
                                            )
                                        )
                                    )}
                                    onChangeComplete={(color: ColorResult) => this.handleColorChange(color)}
                                    disableAlpha={true}
                                    presetColors={[]}
                                    data-test-id="colorpicker"
                                />
                            </Box>
                        </Box>
                        <Paper
                            elevation={3}
                            sx={{
                                width: '35%',
                                backgroundColor: this.state.backgroundColor,
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                borderRadius: '10px',
                                position: 'relative',
                                border: "1px solid white"
                            }}
                        >
                            <Box
                                id="secondary-color-box"
                                style={{
                                    backgroundColor: this.state.secondaryColor,
                                    padding: '5px',
                                    borderRadius: '8px',
                                    height: '126px',
                                    marginLeft: '15%',
                                    marginTop: '5%'
                                }}
                            >
                                <Box sx={{ display: 'flex', mb: 2 }}>
                                    <Box
                                        id="color-box-1"
                                        sx={{
                                            width: '5rem',
                                            height: '5rem',
                                            backgroundColor: '#312F2D',
                                            borderRadius: '4px',
                                            marginRight: '10px',
                                            margin: '4% 3% 3% 4%'
                                        }}
                                    ></Box>
                                    <Box sx={{ width: '180px', marginTop: '23px', marginLeft: '32px' }}>
                                        <Box id="text-box-1" sx={{ width: '60%', height: '12px', backgroundColor: this.state.textColor, mb: '5px', borderRadius: '4px'   }}></Box>
                                        <Box id="text-box-2" sx={{ width: '60%', height: '12px', backgroundColor: this.state.textColor, borderRadius: '4px', mb: '5px' }}> </Box>
                                        <Box id="text-box-3" sx={{ width: '60%', height: '12px', backgroundColor: this.state.textColor, borderRadius: '4px', mb: '5px'  }}></Box>
                                        <Box id="button-box-1" sx={{ width: '30%', height: '12px', backgroundColor: this.state.buttonColor, borderRadius: '4px' }}></Box>
                                        <Box id="button-box-2" style={{ backgroundColor: this.state.buttonColor, marginTop: '2px' }}> </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '65%' }}>
                                    <Box
                                        id="layout-1"
                                        sx={{ display: 'flex', mb: 2, backgroundColor: this.state.secondaryColor, padding: '5px', borderRadius: '8px', marginTop: '5%', marginLeft: '22%' }}
                                    >
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Box
                                                id="layout1-color-box"
                                                sx={{
                                                    width: '60px',
                                                    height: '60px',
                                                    backgroundColor: '#C78932',
                                                    borderRadius: '4px',
                                                    marginBottom: '5px',
                                                }}
                                            ></Box>
                                            <Box sx={{ width: '180px' }}>
                                                <Box id="layout1-button-box-1" sx={{ width: '50%', height: '12px', backgroundColor: this.state.buttonColor, mb: '5px', borderRadius: '4px', ml: '50%' }}> </Box>
                                                <Box id="layout1-button-box-2" sx={{ width: '50%', height: '12px', backgroundColor: this.state.buttonColor, mb: '5px', borderRadius: '4px', ml: '50%'  }}></Box>
                                                <Box id="layout1-text-box-1" sx={{ width: '100%', height: '12px', backgroundColor: this.state.textColor, mb: '5px', borderRadius: '4px'  }}></Box>
                                                <Box id="layout1-text-box-2" sx={{ width: '100%', height: '12px', backgroundColor: this.state.textColor, mb: '5px', borderRadius: '4px' }}> </Box>
                                                <Box id="layout1-text-box-3" sx={{ width: '100%', height: '12px', backgroundColor: this.state.textColor, borderRadius: '4px', mb: '5px' }}></Box>
                                                <Box id="layout1-text-box-4" sx={{ height: '12px', width: '100%', backgroundColor: this.state.textColor, mb: '5px', borderRadius: '4px' }}></Box>
                                                <Box id="layout1-text-box-5" sx={{ width: '100%', height: '12px',  mb: '5px', backgroundColor: this.state.textColor, borderRadius: '4px' }}></Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        id="layout-2"
                                        sx={{ display: 'flex', mb: 2, backgroundColor: this.state.secondaryColor, padding: '5px', borderRadius: '8px', marginTop: '5%', marginLeft: '22%' }}
                                    >
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Box
                                                id="layout2-color-box"
                                                sx={{
                                                    width: '60px',
                                                    height: '60px',
                                                    backgroundColor: '#C78932',
                                                    borderRadius: '4px',
                                                    marginBottom: '5px',
                                                }}
                                            ></Box>
                                            <Box sx={{ width: '180px' }}>
                                                <Box id="layout2-button-box-1" sx={{ width: '50%', height: '12px', backgroundColor: this.state.buttonColor, mb: '5px', ml: '50%', borderRadius: '4px'}}></Box>
                                                <Box id="layout2-button-box-2" sx={{ width: '50%', height: '12px', backgroundColor: this.state.buttonColor, mb: '5px', borderRadius: '4px', ml: '50%' }}></Box>
                                                <Box id="layout2-text-box-1" sx={{ width: '100%', height: '12px', mb: '5px', backgroundColor: this.state.textColor, borderRadius: '4px' }}></Box>
                                                <Box id="layout2-text-box-2" sx={{ width: '100%', height: '12px', backgroundColor: this.state.textColor, mb: '5px', borderRadius: '4px' }}></Box>
                                                <Box id="layout2-text-box-3" sx={{ width: '100%', height: '12px', backgroundColor: this.state.textColor, borderRadius: '4px', mb: '5px'  }}> </Box>
                                                <Box id="layout2-text-box-4" sx={{ width: '100%', height: '12px', backgroundColor: this.state.textColor, mb: '5px', borderRadius: '4px' }}> </Box>
                                                <Box id="layout2-text-box-5" sx={{ width: '100%', height: '12px', borderRadius: '4px', backgroundColor: this.state.textColor, mb: '5px'}}></Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    id="side-panel"
                                    style={{
                                        width: "32%",
                                        height: "90%",
                                        backgroundColor: this.state.secondaryColor,
                                        borderRadius: '8px',
                                        marginTop: '3%'
                                    }}
                                ></Box>
                            </Box>
                            <Box
                                id="circle-box-1"
                                sx={{
                                    position: 'absolute',
                                    top: '15px',
                                    left: '18px',
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    backgroundColor: '#FF0807',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-1"
                                sx={{
                                    position: 'absolute',
                                    top: '65px',
                                    left: '18px',
                                    width: '35px',
                                    height: '35px',
                                    backgroundColor: '#312F2D',
                                    borderRadius: '50%',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-2"
                                sx={{
                                    position: 'absolute',
                                    top: '125px',
                                    left: '23px',
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-3"

                                sx={{
                                    position: 'absolute',
                                    left: '23px',
                                    top: '157px',
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-4"

                                sx={{
                                    position: 'absolute',
                                    top: '187px',
                                    left: '23px',
                                    height: '25px',
                                    width: '25px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-5"

                                sx={{
                                    top: '216px',
                                    left: '23px',
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#312F2D',
                                    position: 'absolute',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-6"
                                sx={{
                                    left: '23px',
                                    top: '247px',
                                    position: 'absolute',
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-7"
                                sx={{
                                    backgroundColor: '#312F2D',
                                    position: 'absolute',
                                    top: '15px',
                                    left: '160px',
                                    width: '30px',
                                    height: '15px',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-8"
                                sx={{
                                    position: 'absolute',
                                    width: '30px',
                                    height: '15px',
                                    top: '15px',
                                    left: '200px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-9"
                                sx={{
                                    position: 'absolute',
                                    top: '15px',
                                    left: '240px',
                                    backgroundColor: '#312F2D',
                                    height: '15px',
                                    width: '30px',
                                }}
                            ></Box>
                            <Box
                                id="rey-circle-box-10"
                                sx={{
                                    position: 'absolute',
                                    top: '15px',
                                    left: '280px',
                                    backgroundColor: '#312F2D',
                                    width: '90px',
                                    height: '15px',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-11"
                                sx={{
                                    top: '15px',
                                    left: '380px',
                                    width: '30px',
                                    height: '15px',
                                    position: 'absolute',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                        </Paper>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: "0.5%"
                        }}
                    >
                        <Button
                            variant="contained"
                            className="continueButton"
                            onClick={this.handleContinue}
                            data-test-id="continueBtn"
                        >
                            Continue
                        </Button>
                    </Box>
                </StyledWrapperSetting>
                <Footer navigation={this.props.navigation} id={"1"} />
            </>
            //Merge Engine End DefaultContainer
            // Customizable Area Start
        );
    }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
    // margin: "0 auto",
    paddingLeft: "calc(2% + 115px)",
    paddingRight: "5%",
    height: "auto",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .continueButton": {
    backgroundColor: '#FF0807',
    color: '#fff',
    textTransform: 'capitalize',
    width: '22%',
    height: '7%',
    borderRadius: '8px',
    fontFamily: 'Nexa-Heavy',
    "&:disabled": {
      backgroundColor: "#B2B3B3"
    },
    "&:hover": {
      backgroundColor: '#FF0807',
      borderColor: '#FF0807'
    }
  }
});

// Customizable Area End
