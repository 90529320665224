import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { activeHost, becomeHostActive, nonActiveBecomeHost, userProfile } from "./assets";
import { Linking } from "react-native";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { getStorageData } from "../../../framework/src/Utilities";
interface UserResponse{
  attributes : {
    first_name : string,
    last_name : string,
    bio : string,
    user_location : string,
    profile_picture : string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  searchValue: string
  activeIcon: string,
  isMenuOpen: boolean,
  activeSidebarIcon: string,
  userStatus: string
  activeHostSidebarIcon: string,
  openDropdown: boolean,
  isClicked: boolean,
  userResponse : UserResponse,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  userDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      searchValue: "",
      activeIcon: '',
      isMenuOpen: false,
      activeSidebarIcon: "",
      userStatus: "resident",
      activeHostSidebarIcon: "",
      openDropdown: false,
      isClicked: false,
      userResponse : {
        attributes:{
          first_name : "",
          last_name : "",
          bio : "",
          user_location : "",
          profile_picture : ""
        }        
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.handleResForuserSettingsApi(from,message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  userDetails =async () => {     
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
       
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleResForuserSettingsApi = async (from: string, message: Message) => {    
    if (this.userDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        
      if (responseJson) {
        console.log("responseData.data",responseJson.data)       
        this.setState({ userResponse:responseJson.data });               
      }
    }
  };
  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.userDetails();
    this.updateActiveIcons();
  
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
  // Customizable Area Start

  handleSearchChange = (event: any) => {
    this.setState({
      searchValue: event.target.value})
  }

  setActiveSidebarIconAndRedirect = (iconName: string, path: string) => {
    this.setState({
      activeSidebarIcon: iconName,
    });
    handleNavigation(path, this.props)
  };

  setActiveIconAndRedirect = (iconName: string, path: string) => {
    this.setState(
      { activeIcon: iconName },
      () => {
        handleNavigation(path, this.props);
      }
    );
  };

  setActiveHostSidebarIconAndRedirect = (iconName: string, path: string) => {
    this.setState(
      { activeIcon: iconName },
      () => {
        handleNavigation(path, this.props);
      }
    );
  };
  getHostIcon(){
    const { activeIcon, userStatus } = this.state
    const hostMember = (activeIcon === "host" && userStatus === 'host') && activeHost
    const nonHostMember = (activeIcon === "host" && userStatus != 'host') && becomeHostActive
    const nonActiveIcon = activeIcon != "host" && nonActiveBecomeHost
    
    return hostMember || nonHostMember || nonActiveIcon
  }

  residentMember(){
    return Boolean(this.state.userStatus === 'resident' && this.state.activeIcon !== 'host')
  }

  potentialHostMember(){
    return Boolean(this.state.userStatus != 'host' && this.state.activeIcon === 'host')
  }

  hostMember(){
    return Boolean(this.state.userStatus === 'host' && this.state.activeIcon === 'host')
  }

  handleProfilePhotoClick = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  }

  onLogoClick = () => {
    if (this.state.userStatus === 'resident') {
      handleNavigation("Dashboard", this.props)
      this.setState({
        activeIcon: 'resident',
        activeSidebarIcon: 'dashboard',
      });
    }
  }

  updateActiveIcons = () => {
    const { userStatus } = this.state;
    const pathname = window.location.pathname;
  
    if (!this.state.activeIcon) {
      if (userStatus === 'resident' && pathname === '/Dashboard') {
        this.setState({
          activeIcon: 'resident',
          activeSidebarIcon: 'dashboard',
        });
      } else if (pathname.toLowerCase().includes('host')) {
        this.setState({
          activeIcon: 'host',
          activeHostSidebarIcon: 'myChannel',
      });
    }
    }
  }

  // Customizable Area End
}
