// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  isLinkValid: boolean,
  step: number,
  backgroundColor: string,
  secondaryColor: string,
  textColor: string,
  buttonColor: string,

}

export interface SS {
  id: any;
}

export default class HostSetupRssFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: true,
      step: 1,
      backgroundColor: '#030303',
      secondaryColor: '#FBBF24',
      textColor: '#030303',
      buttonColor: '#6200EA',
    };
  }

  handleGoBack = () => {
    if(1 < this.state.step) {
      this.setState({step: this.state.step - 1})
    }
    else {
    this.props.navigation.goBack()
    }
  }
  handleColorChange = (color: { hex: string }) => {
    let step =this.state.step;
    if(step === 1) {
        this.setState({ backgroundColor: color.hex })
    }
    else if(step === 2) {
        this.setState({ secondaryColor: color.hex })
    }
    else if(step === 3) {
        this.setState({ textColor: color.hex })
    }
    else if(step === 4) {
        this.setState({ buttonColor: color.hex })
    }
  }
  handleColorManually = (event: { target: { value: string; } } | React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value; 
    const step = this.state.step;

    switch (step) {
        case 1:
            this.setState({ backgroundColor: color });
            break;
        case 2:
            this.setState({ secondaryColor: color });
            break;
        case 3:
            this.setState({ textColor: color });
            break;
        case 4:
            this.setState({ buttonColor: color });
            break;
        default:
            break;
    }
};
getAllStorageData = () => {
  const data: Record<string, any> = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key !== null) { 
      const value = localStorage.getItem(key);
      if (value !== null) {
          data[key] = value; 
      }
  }
  }
  return data;
};
  handleContinue = () => {
    let step = this.state.step;
    if (step < 4) {
      this.setState({ step: step + 1 });
    }
    else if (step === 4) {
      let storageData = this.getAllStorageData();
      if (!storageData['rssData']) {
        storageData['rssData'] = {}; 
      }
      else {
        storageData['rssData'] = JSON.parse(storageData['rssData'])
      }
      storageData['rssData']['backgroundColor'] = this.state.backgroundColor;
      storageData['rssData']['secondaryColor'] = this.state.secondaryColor;
      storageData['rssData']['textColor'] = this.state.textColor;
      storageData['rssData']['buttonColor'] = this.state.buttonColor;
      setStorageData("rssData",JSON.stringify(storageData['rssData']));
      handleNavigation("TemplatePreviewWithLogo", this.props)
    }
  }

}
// Customizable Area End
