export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const backgroundImg = require("../assets/backgroundImg.jpeg").default;
export const mainLogo = require("../assets/mainLogo.png").default;
export const logoSvg = require("../assets/logoSvg.svg").default;
export const visibility_icon = require("../assets/visibility_copy.svg").default;
export const checkbox = require("../assets/checkbox.svg").default;
export const visibility_cross = require("../assets/visibilityCross.svg").default;
export const darkBg = require("../assets/darkIcon.png").default;
export const lightBg = require("../assets/lightIcon.png").default;
export const checkboxTicked  = require("../assets/tickedCheckbox.png").default;
export const hostBg  = require("../assets/hostImage.png").default;
export const star = require("../assets/star_24px.png").default;
export const leftImage  = require("../assets/imageLeft.png").default;
export const rightImage  = require("../assets/imageRight.png").default;
export const group_applepay  = require("../assets/group_applepay.png").default;
export const group_googlepay  = require("../assets/group_googlepay.png").default;
export const group_klarna  = require("../assets/group_klarna.png").default;
export const group_maestro  = require("../assets/group_maestro.png").default;
export const group_paypal  = require("../assets/group_paypal.png").default;
export const group_visa  = require("../assets/group_visa.png").default;
export const  cart = require("../assets/image_cta.png").default;
export const rssFeedBackground = require("../assets/rss_feed_background.png").default;
export const validated = require("../assets/validated.svg").default;
export const not_validated = require("../assets/not_validated.svg").default;
export const validatedPreview = require("../assets/validLinkPreview.png").default;
export const preview1440 = require("../assets/preview1440.png").default;
export const goBack = require("../assets/goBack.svg").default;
export const  upload = require("../assets/upload.png").default;
export const image_group = require("../assets/image_group.png").default;
export const image_group_1 = require("../assets/image_group_1.png").default;
export const podcastImage = require("../assets/product_card_small_item_gen.png").default;
export const tshirtIcon = require("../assets/tshirtIcon.png").default;
export const recommended_episode_1 = require("../assets/image3.png").default;
export const recommended_episode_2 = require("../assets/image4.png").default;
export const recommended_episode_3 = require("../assets/image5.png").default;
export const ad_1 = require("../assets/image1.png").default;
export const ad_2 = require("../assets/image2.png").default;
export const template1 = require("../assets/template1.png").default;
export const template2 = require("../assets/template2.png").default;
export const template3 = require("../assets/template3.png").default;
export const template4 = require("../assets/template4.png").default;
export const appLogo = require("../assets/appLogo.png").default;


