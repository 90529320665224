// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  isLinkValid: boolean,
  podcastData: Podcast[],
  openFirstDialog: boolean,
  openSecondDialog: boolean,
  selectedFile: File | null,
  imagePreviewUrl: string
}

export interface SS {
  id: any;
}

interface Podcast {
  id: number,
  sender: string,
  subject: string,
  snippet: string,
  time: string,
  isRead: boolean
}

export default class TemplatePreviewWithLogoController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchRssFeedCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: true,
      podcastData: [],
      openFirstDialog: true,
      openSecondDialog: false,
      selectedFile: null,
      imagePreviewUrl: "",
    };
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  handleCloseFirstDialog = () => {
    this.setState({ openFirstDialog: false });
  };

  handleOpenSecondDialog = () => {
    this.setState({
      openFirstDialog: false,
      openSecondDialog: true
    });
  };

  handleCloseSecondDialog = () => {
    this.setState({ openSecondDialog: false });
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      this.setState({ selectedFile: file, imagePreviewUrl: imageUrl });
    }
  };
}
// Customizable Area End
