// App.js - WEB
import React, { useState } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import HomeScreen from '../../components/src/HomeScreen';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
// import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Analytics from "../../blocks/analytics/src/Analytics";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web.tsx";
import HostPaymentPlan from "../../blocks/email-account-login/src/HostPaymentPlan.web.tsx";
import HostLogin from "../../blocks/email-account-login/src/HostLogin.web.tsx";
import HostSetupMethod from "../../blocks/email-account-login/src/HostSetupMethod.web.tsx";
import SetUpProfileThirdScreenNoRss from "../../blocks/email-account-login/src/SetUpProfileThirdScreenNoRss.web.tsx";
import HostPaymentDetails from "../../blocks/email-account-login/src/HostPaymentDetails.web.tsx";
import SetupPodcastProfileNoRssFeed from "../../blocks/email-account-login/src/SetupPodcastProfileNoRssFeed.web.tsx";
import EmailRegistrationCategories from "../../blocks/email-account-registration/src/EmailRegistrationCategories.web";
import EmailRegistrationSocialLinks from "../../blocks/email-account-registration/src/EmailRegistrationSocialLinks.web";
import EmailForgotPasswordScreenSecond from "../../blocks/email-account-registration/src/EmailForgotPasswordScreenSecond.web.tsx";
import EmailForgotPassword from "../../blocks/email-account-registration/src/EmailForgotPassword.web.tsx";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SetUpProfileSecondScreenNoRss from "../../blocks/email-account-login/src/SetUpProfileSecondScreenNoRss.web.tsx";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Videos from "../../blocks/videos/src/Videos";
import AudioPlayerFull from "../../blocks/audiolibrary/src/components/AudioPlayerFullScreen.web.tsx";
import Notifications from "../../blocks/notifications/src/Notifications";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Search from "../../blocks/search/src/Search";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import HostSetupRssFeed from "../../blocks/email-account-login/src/HostSetupRssFeed.web.tsx";
import SetupRssFeedPreview from "../../blocks/email-account-login/src/components/SetupRssFeedPreview.web.tsx";
import TemplateColorScheme from "../../blocks/email-account-login/src/TemplateColorScheme.web.tsx";
import TemplatePreviewWithLogo from "../../blocks/email-account-login/src/TemplatePreviewWithLogo.web.tsx";
import ChooseTemplate from "../../blocks/email-account-login/src/ChooseTemplate.web.tsx";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Pagination from "../../blocks/pagination/src/Pagination";
import DataStorage from "../../blocks/datastorage/src/DataStorage";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import RSSFeeds from "../../blocks/rssfeeds/src/RSSFeeds";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
HostPaymentPlan:{
  component:HostPaymentPlan,
 path:"/HostPaymentPlan"},
HostLogin : {
  component : HostLogin,
  path:"/HostLogin"
},
HostSetupMethod : {
  component : HostSetupMethod,
  path : "/HostSetupMethod"
},
SetUpProfileThirdScreenNoRss : {
  component : SetUpProfileThirdScreenNoRss,
  path : "/SetUpProfileThirdScreenNoRss"
},
HostPaymentDetails : {
  component : HostPaymentDetails,
  path:"/HostPaymentDetails"
},
SetupPodcastProfileNoRssFeed:{
  component : SetupPodcastProfileNoRssFeed,
  path : "/SetupPodcastProfileNoRssFeed" 
},
EmailRegistrationCategories:{
  component:EmailRegistrationCategories,
 path:"/EmailRegistrationCategories"},
 EmailRegistrationSocialLinks:{
  component:EmailRegistrationSocialLinks,
  path:"/EmailRegistrationSocialLinks"
 },
 EmailForgotPasswordScreenSecond : {
  component : EmailForgotPasswordScreenSecond,
  path : "/EmailForgotPasswordScreenSecond"
 },
 SetUpProfileSecondScreenNoRss :{
  component : SetUpProfileSecondScreenNoRss,
  path : "SetUpProfileSecondScreenNoRSS"
 },
 EmailForgotPassword:{
  component:EmailForgotPassword,
  path : "/EmailForgotPassword"
 },
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Videos:{
 component:Videos,
path:"/VideoPlayer"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Search:{
 component:Search,
path:"/Search"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Pagination:{
 component:Pagination,
path:"/Pagination"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
RSSFeeds:{
 component:RSSFeeds,
path:"/RSSFeeds"},
// Share:{
//  component:Share,
// path:"/Share"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Dashboard:{
  component:Dashboard,
 path:"/Dashboard"},
HostSetupRssFeed:{
component: HostSetupRssFeed,
path: "/HostSetupRssFeed"
},
SetupRssFeedPreview:{
  component: SetupRssFeedPreview,
  path: "/SetupRssFeedPreview"
},
AudioPlayerFull: {
  component: AudioPlayerFull,
  path: "/AudioPlayerFullScreen"
},
TemplateColorScheme:{
  component: TemplateColorScheme,
  path: "/TemplateColorScheme"
},
TemplatePreviewWithLogo:{
  component: TemplatePreviewWithLogo,
  path: "/TemplatePreviewWithLogo"
},
ChooseTemplate:{
  component: ChooseTemplate,
  path: "/ChooseTemplate"
},
  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;